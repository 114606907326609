<script>
/* eslint-disable */
import axios from 'axios';

import {
  authMethods,
  notificationMethods,
} from '@/state/helpers';
import appConfig from '@/app.config';

import {required, email, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/**
 * Login sample page
 */
export default {
  setup() {
    return {v$: useVuelidate()};
  },
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: 'admin@themesbrand.com',
      password: '123456',
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required: helpers.withMessage('Email is required', required),
      email: helpers.withMessage('Please enter valid email', email),
    },
    password: {
      required: helpers.withMessage('Password is required', required),
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {

      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logIn({
              email: this.email,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || {
                    name: 'default',
                  },
                );
              }).catch((error) => {
              this.tryingToLogIn = false;
              this.authError = error || '';
              this.isAuthError = true;
            })
          );
        }
        if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
          const {email, password} = this;
          if (email && password) {
            this.login({
              email,
              password,
            });
          }
        } else if (process.env.VUE_APP_DEFAULT_AUTH === 'authapi') {
          axios.post('http://127.0.0.1:8000/api/login', {
            email: this.email,
            password: this.password,
          }).then((res) => res);
        }
      }
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Welcome Back !</h5>
                    <p>Sign in to continue to Skote.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img
                    alt
                    class="img-fluid"
                    src="@/assets/images/profile-img.png"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <router-link to="/">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img alt height="34" src="@/assets/images/logo.svg"/>
                    </span>
                  </div>
                </router-link>
              </div>
              <b-alert
                v-model="isAuthError"
                class="mt-3"
                dismissible
                variant="danger"
              >{{ authError }}
              </b-alert
              >

              <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
              >
                {{ notification.message }}
              </div>

              <b-form class="p-2" @submit.prevent="tryToLogIn">
                <b-form-group
                  id="input-group-1"
                  class="mb-3"
                  label="Email"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }"
                    placeholder="Enter email"
                    type="text"
                  ></b-form-input>
                  <div
                    v-for="(item, index) in v$.email.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  class="mb-3"
                  label="Password"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="password"
                    :class="{ 'is-invalid': submitted && v$.password.$error }"
                    placeholder="Enter password"
                    type="password"
                  ></b-form-input>
                  <div
                    v-if="submitted && v$.password.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.password.required.$message">{{
                        v$.password.required.$message
                      }}</span>
                  </div>
                </b-form-group>
                <b-form-checkbox
                  id="customControlInline"
                  class="form-check"
                  name="checkbox-1"
                  unchecked-value="not_accepted"
                  value="accepted"
                >
                  Remember me
                </b-form-checkbox>
                <div class="mt-3 d-grid">
                  <b-button class="btn-block" type="submit" variant="primary"
                  >Log In
                  </b-button
                  >
                </div>
                <div class="mt-4 text-center">
                  <h5 class="font-size-14 mb-3">Sign in with</h5>

                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        class="
                          social-list-item
                          bg-primary
                          text-white
                          border-primary
                        "
                        href="javascript: void(0);"
                      >
                        <i class="mdi mdi-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-list-item bg-info text-white border-info"
                        href="javascript: void(0);"
                      >
                        <i class="mdi mdi-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="
                          social-list-item
                          bg-danger
                          text-white
                          border-danger
                        "
                        href="javascript: void(0);"
                      >
                        <i class="mdi mdi-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="mt-4 text-center">
                  <router-link class="text-muted" to="/forgot-password">
                    <i class="mdi mdi-lock me-1"></i> Forgot your password?
                  </router-link>
                </div>
              </b-form>
            </div>
            <!-- end card-body -->
          </div>
          <div class="mt-5 text-center">
            <p>
              Don't have an account ?
              <router-link class="fw-medium text-primary" to="/auth/register-1"
              >Signup now
              </router-link
              >
            </p>
            <p>
              © {{ new Date().getFullYear() }} Skote. Crafted with
              <i class="mdi mdi-heart text-danger"></i> by Themesbrand
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
